<template>
    <textarea id="uuid" v-model="editorText"> </textarea>
</template>

<script>
export default {
    name: 'Editor',
    components: {},
    props: {
        text: {
            required: true,
        },
        config: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        toolbar: {
            default:
                'formatselect fontsizeselect | styleselect bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | preview | undo redo',
        },
        plugins: {
            default:
                // 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table contextmenu paste hr wptextcolor',
                'advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools',
        },
    },
    data() {
        return {
            textVal: this.text,
        };
    },
    methods: {
        initEditor() {
            const cfg = Object.assign(
                {},
                {
                    selector: '#uuid',
                    plugins: this.plugins,
                    toolbar1: this.toolbar,
                    height: 500,
                    inline: false,
                    resize: false,
                    branding: false,
                    fontsize_formats:
                        '8px 10px 12px 14px 16px 18px 20px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px',
                    themes: 'modern',
                    extended_valid_elements:
                        'img[onmouseover|onmouseout|src|alt|name|title|height|width|onclick],iframe[*],div[*],span[*],p[*],button[type|style|onclick]',
                    code_dialog_height: 200,
                    init_instance_callback: (editor) => {
                        editor.execCommand('fontSize', false, '14px');

                        editor.on('KeyUp', (e) => {
                            this.$emit('update:text', editor.getContent());
                        });
                        editor.on('Change', (e) => {
                            this.$emit('update:text', editor.getContent());
                        });
                    },
                },
                this.config
            );
            tinymce.init(cfg);
        },
        updateEditorContent(newContent) {
            const editor = tinymce.get('uuid');
            if (editor && newContent !== editor.getContent()) {
                editor.setContent(newContent || ''); // ← fallback to empty string
            }
        },
    },
    watch: {
        text(newVal) {
            this.updateEditorContent(newVal);
        },
    },
    computed: {
        editorText: {
            get() {
                return this.text;
            },
            set(newVal) {
                this.$emit('update:text', newVal);
            },
        },
    },
    mounted() {
        this.initEditor();
    },
    beforeDestroy() {
        tinymce.EditorManager.remove();
    },
};
</script>
<style scoped>
.wp-picker-container input[type='text'].wp-color-picker {
    line-height: 22px;
    height: 24px;
}

.wp-picker-container {
    padding-top: 10px;
}

.wp-picker-input-wrap {
    margin: 0 10px;
}

.wp-color-result {
    display: none;
}

.wp-color-picker {
    display: inline !important;
}

.wp-picker-clear {
    display: none !important;
}

.wp-picker-container .iris-picker {
    position: relative;
    display: block !important;
    height: 200px !important;
    width: 220px !important;
    margin-top: 0;
    padding: 0;
}

.wp-picker-input-wrap {
    position: absolute;
    z-index: 1;
    display: none;
}

.iris-picker-inner {
    position: absolute;
    top: 35px !important;
    display: none;
}

.iris-palette-container {
    position: absolute;
    top: 0;
    width: 200px;
}

.iris-palette {
    width: 16px !important;
    height: 16px !important;
    margin: 2px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    position: relative;
    background-color: #eee;
}

.iris-palette:focus {
    outline: none;
}

.iris-palette:hover:after,
.iris-palette.active:after {
    width: 18px;
    height: 18px;
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -2px;
    border: 1px solid #333;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
}

.tinymce-cp-footer {
    padding: 10px;
}

.wp-core-ui .tinymce-cp-custom {
    display: block;
    float: left;
}

.wp-core-ui .tinymce-cp-apply {
    float: right;
}

.iris-picker .iris-square {
    width: 160px !important;
    height: 160px !important;
    margin-right: 10px;
}

.iris-picker .iris-slider {
    height: 160px !important;
}

.mce-toolbar .mce-colorbutton .mce-preview {
    margin-left: 0;
    top: auto;
    bottom: 2px;
    left: 3px;
    height: 3px;
    width: 20px;
}

i.mce-i-backcolor {
    font: normal 20px/1 'dashicons';
    padding: 0;
    vertical-align: top;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: -2px;
    padding-right: 2px;
    content: '\f215';
}

i.mce-i-backcolor:before {
    content: '\f215';
}
</style>
